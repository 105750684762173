import React, { useState } from "react";
import Select from "react-select";
import { provinces, citiesOfProvinces } from "../../DummyData";
import PhoneInput from "react-phone-input-2";
import { Spinner, Container, Col, Row, Button } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import { Storage } from "aws-amplify";
import { Auth } from "aws-amplify";
import { updateSellerData } from "../../../api/sellers/sellers";
import OtpModal from "./OtpModal";
import PinCodeModal from "./PinCodeModal";
import { useTranslation } from "react-i18next";

const EditInformation = ({ user }) => {
  const accountType = user.account;
  const businessDomain = user.businessCategory;
  const { t, i18n } = useTranslation();
  const [logoImage, setLogoImage] = useState(user.businessInfo?.storeLogo);
  const [name, setName] = useState(user.businessInfo?.ownerName);
  const [storeName, setStoreName] = useState(user.businessInfo?.storeName);
  const [contactNumber, setContactNumber] = useState(
    user.businessInfo?.phoneNumber
  );
  const [openingTime, setOpeningTime] = useState(
    user.businessInfo?.openingTime
  );
  const [closingTime, setClosingTime] = useState(
    user.businessInfo?.closingTime
  );
  const [nationality, setNationality] = useState(user.nationality);
  const [dob, setDob] = useState(user.dob);
  const [gender, setGender] = useState(user.gender);
  const [nif, setNif] = useState(user.businessInfo?.nif);
  const [iban, setIban] = useState(user.bankDetails?.iban?.substring(4));
  const [address, setAddress] = useState(user.businessAddress?.address);
  const [province, setProvince] = useState(user.businessAddress?.province);
  const [city, setCity] = useState(user.businessAddress?.city);
  const [currCities, setCurrCities] = useState(
    citiesOfProvinces.find(
      (item) => item.province === user.businessAddress?.province
    )?.cities
  );
  const [documentNumber, setDocumentNumber] = useState(
    user.documents?.documentNumber || ""
  );
  const [attachmentFile, setAttachmentFile] = useState(
    user.documents?.attachmentFile || null
  );

  const [document, setDocument] = useState("");
  const [updatedocs, setupdatedocs] = useState("");
  const [doc, setDoc] = useState("");
  const [emptyFieldError, setEmptyFieldError] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showDeleteText, setShowDeleteText] = useState(false);
  const [loading, setLoading] = useState(false);
  const [verificationModal, setVerificationModal] = useState(false);
  const [updateBody, setUpdateBody] = useState(null);
  const [bank, setBank] = useState("");
  const otpModalToggle = (isRefresh = false) => {
    setVerificationModal(!verificationModal);
    if (isRefresh) {
      user.businessInfo.phoneNumber = contactNumber;
    }
  };

  const [pinCodeModal, setPinCodeModal] = useState(false);
  const pinCodeModalToggle = (isRefresh = false) => {
    setPinCodeModal(!pinCodeModal);
    if (isRefresh) {
      user.bankDetails.iban = `AO06${iban}`;
    }
  };
  const options = [
    { value: "BANCO BNI", label: "Banco BNI" },
    { value: "Banco BFA", label: "Banco BFA" },
    { value: "Banco BAI", label: "Banco BAI" },
    { value: "BANCO BCA", label: "BANCO BCA" },
    { value: "Banco BCI", label: "Banco BCI" },
    { value: "Banco BAI", label: "Banco BAI" },
    { value: "Banco BIR", label: "Banco BIR" },
    { value: "Banco YETU", label: "Banco YETU" },
    { value: "Banco BPC", label: "Banco BPC" },
    { value: "Banco KEVE", label: "Banco KEVE" },
    { value: "Banco SOL", label: "Banco SOL" },
    { value: "Banco VALOR", label: "Banco VALOR" },
    { value: "Banco DA CHINA", label: "Banco DA CHINA" },
    { value: "STANDARD BANK", label: "STANDARD BANK" },

    { value: "Banco VTB ÁFRICA", label: "Banco VTB ÁFRICA" },
    {
      value: "Banco MILLENNIUM ATLÂNTICO",
      label: "Banco MILLENNIUM ATLÂNTICO",
    },
    { value: "Banco CAIXA GERAL ANGOLA", label: "Banco CAIXA GERAL ANGOLA" },
    {
      value: "BCS – BANCO DE CRÉDITO DO SUL",
      label: "BCS – BANCO DE CRÉDITO DO SUL",
    },

    {
      value: "BANCO COMERCIAL DO HUAMBO ",
      label: "BANCO COMERCIAL DO HUAMBO",
    },
  ];
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  const onDeleteImage = async (file) => {
    file = file.split("public/")[1];

    // setLoading(true)

    try {
      await Storage.remove(file).then((res) => {
        // setLoading(false)
      });
    } catch (e) {
      // setLoading(false)
    }
  };

  const onFileChange = async (e) => {
    setUpdateLoading(true);
    const files = e.target.files;

    try {
      const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
      const result = await Storage.put(fileKey, files[0], {
        contentType: "image/jpeg", // contentType is optional
      });

      fetchImageUrl(result);
    } catch (error) {
      setUpdateLoading(false);
    }

    setUpdateLoading(false);

    const filePath = URL.createObjectURL(files[0]);
    setLogoImage(filePath);
    // for (let i = 0; i < files.length; i++) {
    //   const filePath = URL.createObjectURL(files[i]);
    //   setImgPaths((imgPaths) => [...imgPaths, filePath]);
    // }
  };

  const fetchImageUrl = async (key) => {
    const Url = `${process.env.REACT_APP_S3_URL}/public/${key.key}`;
    setLogoImage(Url);
  };
  const handleUploadDocument = async (e) => {
    const imageLink = await uploadDocument(e);
    setAttachmentFile(imageLink);
    // setDocument(imageLink);
    // setupdatedocs("updated");
    // setDoc(e.target.files[0]);
  };
  const uploadDocument = async (e) => {
    setLoading(true);
    const files = e.target.files;
    let Url = "";

    try {
      const fileKey = uuid() + files[0].name.replace(/\s/g, "-").toLowerCase();
      const result = await Storage.put(fileKey, files[0]);

      Url = `${process.env.REACT_APP_S3_URL}/public/${result.key}`;
    } catch (error) {
      setLoading(false);
    }

    setLoading(false);
    return Url;
  };

  const checkIBANLength = (number) => {
    const length = number.toString().length;

    if (length === 21) {
      return true;
    } else {
      return false;
    }
  };

  const handleNewPhone = async (phone) => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const result = await Auth.updateUserAttributes(user, {
        phone_number: `+${phone}`,
      });
    } catch (error) {}
  };

  const updateSeller = () => {
    setUpdateLoading(true);
    if (
      storeName !== "" &&
      name !== "" &&
      nif !== "" &&
      contactNumber !== "" &&
      (!(
        businessDomain === "Bar & Restaurant" || businessDomain === "Fast Food"
      ) ||
        openingTime !== "") &&
      (!(
        businessDomain === "Bar & Restaurant" || businessDomain === "Fast Food"
      ) ||
        closingTime !== "") &&
      logoImage !== "" &&
      address !== "" &&
      province !== "" &&
      city !== "" &&
      iban !== "" &&
      bank !== "" &&
      documentNumber !== "" &&
      attachmentFile !== null
    ) {
      const body = {
        gender: gender,
        nationality: nationality,
        dob,
        // account: accountType,
        // pincode: pinCode,
        businessInfo: {
          storeLogo: logoImage,
          openingTime: openingTime,
          closingTime: closingTime,
          nif: nif,
          storeName,
          ownerName: name,
          phoneNumber: contactNumber,
        },
        businessAddress: {
          address,
          province: province,
          city: city,
        },
        documents: {
          documentNumber: documentNumber,
          attachmentFile: attachmentFile,
        },
        bankDetails: {
          bankName: bank,
        },
        // documents: {
        //   bankName: selectedBank,
        //   idType: id,
        //   documentNumber,
        //   imgUrl: logo,
        // },
        // businessCategory: businessDomain,
      };
      if (contactNumber !== user.businessInfo.phoneNumber) {
        setUpdateBody(body);
        handleNewPhone(contactNumber);
        otpModalToggle();
      } else if (`AO06${iban}` !== user.bankDetails.iban) {
        if (checkIBANLength(iban)) {
          body.bankDetails = {};
          body.bankDetails.iban = `AO06${iban}`;
          setUpdateBody(body);
          pinCodeModalToggle();
        } else {
          setEmptyFieldError("IBAN too short.");
        }
      } else {
        updateSellerData(body)
          .then((res) => {
            setUpdateLoading(false);
          })
          .catch((error) => {});
      }
    } else {
      setUpdateLoading(false);
      setEmptyFieldError("All fields are required");
    }
  };

  return (
    <>
      <Container fluid>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={8}>
            <h5>{t("Personal Information")}</h5>
          </Col>
        </Row>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Store Logo")}
            </label>
            {logoImage && (
              <>
                <div className="d-flex flex-column">
                  <img
                    alt=""
                    style={{
                      marginRight: "10px",
                      // marginBottom: "10px",
                      marginTop: "10px",
                    }}
                    src={logoImage}
                    width={67}
                    height={67}
                    onClick={() => {
                      setLogoImage("");
                      onDeleteImage(logoImage);
                    }}
                    onMouseEnter={() => setShowDeleteText(true)}
                    onMouseLeave={() => setShowDeleteText(false)}
                  />
                </div>
                <p
                  style={{
                    color: "red",
                    fontSize: "12px",
                    visibility: !showDeleteText ? "hidden" : "visible",
                  }}
                >
                  {t("*click to delete")}
                </p>
              </>
            )}
            <input
              style={{ display: "none" }}
              id="imageUpload"
              type="file"
              onChange={(e) => onFileChange(e)}
            />
            {!logoImage && (
              <label
                // style={{
                //   visibility: logoImage ? "hidden" : "initial",
                // }}
                htmlFor="imageUpload"
                className="imgUploadLbl"
              >
                +
              </label>
            )}
          </Col>
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Owner name")}
            </label>
            <input
              type="text"
              required
              className="form-control"
              placeholder="eg: Andy Robert"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {accountType === "individual"
                ? t("Store name")
                : t("Business Name")}
            </label>
            <input
              type="text"
              required
              className="form-control"
              value={storeName}
              onChange={(e) => {
                setStoreName(e.target.value);
              }}
            />
          </Col>
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {accountType === "individual"
                ? t("Phone number")
                : t("Business Phone number")}
            </label>
            <PhoneInput
              disableDropdown
              containerStyle={{ width: "100%" }}
              inputStyle={{ width: "100%" }}
              country="pk"
              placeholder="+923001234567"
              value={contactNumber}
              onChange={setContactNumber}
            />
          </Col>
        </Row>
        {(businessDomain === "Bar & Restaurant" ||
          businessDomain === "Fast Food") && (
          <Row sm={8} className="justify-content-md-center">
            <Col sm={4}>
              <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Opening Time")}
              </label>
              <input
                type="time"
                required
                className="form-control"
                placeholder="eg: Andy Robert"
                value={openingTime}
                onChange={(e) => {
                  setOpeningTime(e.target.value);
                }}
              />
            </Col>
            <Col sm={4}>
              <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Closing Time")}
              </label>
              <input
                type="time"
                required
                className="form-control"
                placeholder="eg: Andy Robert"
                value={closingTime}
                onChange={(e) => {
                  setClosingTime(e.target.value);
                }}
              />
            </Col>
          </Row>
        )}
        {accountType === "individual" && (
          <>
            <Row sm={8} className="justify-content-md-center">
              <Col sm={4}>
                <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {t("Nationality")}
                </label>
                <input
                  type="text"
                  required
                  className="form-control"
                  placeholder="eg: Angolan"
                  value={nationality}
                  onChange={(e) => setNationality(e.target.value)}
                />
              </Col>
              <Col sm={4}>
                <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {t("Date of birth")}
                </label>
                <input
                  type="date"
                  required
                  className="form-control"
                  placeholder="dd-mm-yyyy"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              </Col>
            </Row>
            <Row sm={8}>
              <Col sm={4}>
                <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                  {t("Gender")}
                </label>
                <Select
                  options={[
                    { label: t("Male"), value: "Male" },
                    { label: t("Female"), value: "Female" },
                  ]}
                  value={{ value: gender, label: gender }}
                  onChange={({ value }) => setGender(value)}
                />
              </Col>
              <Col sm={4} style={{ visibility: "hidden" }}>
               
              </Col>
            </Row>
          </>
        )}
        <Row
          style={{ marginTop: "20px" }}
          sm={8}
          className="justify-content-md-center"
        >
          <Col sm={8}>
            <h5>{t("Bank Details")}</h5>
          </Col>
        </Row>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {accountType === "individual" ? "NIF" : t("Business NIF")}
            </label>
            <input
              type="number"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
              required
              className="form-control"
              value={nif}
              onChange={(e) => setNif(e.target.value)}
            />
          </Col>
          {/* Iban */}
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {accountType === "individual" ? "IBAN" : "Business IBAN"}
            </label>
            <div className="input-box" style={{ width: "100%" }}>
              <span className="prefix">AO06</span>
              <input
                required
                maxLength="21"
                onInput={maxLengthCheck}
                type="number"
                onKeyDown={(e) =>
                  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                }
                value={iban}
                onChange={(e) => setIban(e.target.value)}
                placeholder="XXXXXXXXXXXXXXXXXXXXX"
              />
            </div>
          </Col>
          
          <Row sm={8} className="justify-content-md-center">
            <Col
              sm={4}
              style={{
                position: "relative",
                paddingLeft:"0px"
              }}
            >
              <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                {t("Select Bank")}
                <span style={{ color: "red" }}> *</span>
              </label>
              <Select
                options={options}
                onChange={(value) => setBank(value.value)}
              />
              {bank !== "" && (
                <span
                  style={{
                    position: "absolute",
                    color: "rgb(153, 153, 153)",
                    right: "0",
                    fontSize: "12px",
                  }}
                >
                  {bank === options[0].value
                    ? `${t("Commission")} 250 AKZ, IVA 35 AKZ`
                    : `${t("Commission")} 650 AKZ, IVA 91 AKZ`}
                </span>
              )}
            </Col>
            <Col sm={4}  style={{ visibility: "hidden" }}>
              
            </Col>
          </Row>
        </Row>

        <Row sm={8}>
          <Col sm={8}>
            <label
              htmlFor="addressInput"
              style={{ fontSize: "12px", fontWeight: "bold" }}
            >
              {t("Address")}
            </label>
            <br />
            <textarea
              // className="input-box"
              value={address}
              onChange={(e) => {
                // setEmptyFieldError("");
                setAddress(e.target.value);
              }}
              id="addressInput"
              rows="5"
              maxLength={"256"}
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ced4da",
              }}
            />
          </Col>
        </Row>
        <Row
          style={{ marginTop: "20px" }}
          sm={8}
          className="justify-content-md-center"
        >
          <Col sm={8}>
            <h5>{t("Documents")}</h5>
          </Col>
        </Row>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Document Number")}
            </label>
            <input
              type="text"
              required
              className="form-control"
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
            />
          </Col>
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Attachmentfile/photo")}
            </label>
            <input
              type="file"
              required
              accept="application/pdf, image/*"
              className="form-control"
              onChange={handleUploadDocument}
            />
          </Col>
        </Row>
        <Row sm={8} className="justify-content-md-center">
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("Province")}
            </label>
            <Select
              value={{ value: province, label: province }}
              options={provinces}
              onChange={({ value }) => {
                // setEmptyFieldError("");
                setProvince(value);

                // selectInputRef.current.clearValue();
                setCurrCities(
                  citiesOfProvinces.find((item) => item.province === value)
                    .cities
                );
              }}
            />
          </Col>
          <Col sm={4}>
            <label style={{ fontSize: "12px", fontWeight: "bold" }}>
              {t("City")}
            </label>
            <Select
              // ref={selectInputRef}
              value={{ value: city, label: city }}
              options={currCities}
              onChange={(value) => {
                setEmptyFieldError("");
                setCity(value.value);
              }}
            />
          </Col>
        </Row>
        {emptyFieldError && (
          <p
            style={{
              color: "red",
              fontSize: "13px",
            }}
          >
            {t(`${emptyFieldError}`)}
          </p>
        )}
        <Row sm={8} className="justify-content-md-center">
          <Col sm={4}>
            <Button
              onClick={(e) => updateSeller(e)}
              disabled={updateLoading}
              style={{
                margin: "20px 0px",
                backgroundColor: "#049EFF",
                borderWidth: 0,
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {updateLoading ? (
                <Spinner
                  animation="border"
                  style={{ color: "white", padding: "9px" }}
                  size="sm"
                />
              ) : (
                t("Save")
              )}
            </Button>
          </Col>
        </Row>
      </Container>
      <OtpModal
        toggle={otpModalToggle}
        modal={verificationModal}
        body={updateBody}
      />
      <PinCodeModal
        toggle={pinCodeModalToggle}
        modal={pinCodeModal}
        body={updateBody}
      />
    </>
  );
};

export default EditInformation;
